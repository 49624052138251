import $ from "jquery";
var iframeTarget = process.env.REACT_APP_CDN_SERVER + "/index.html"; // addEventListener support for IE8

function bindEvent(element, eventName, eventHandler) {
  if (element.addEventListener) {
    element.addEventListener(eventName, eventHandler, false);
  } else if (element.attachEvent) {
    element.attachEvent("on" + eventName, eventHandler);
  }
} // Listen to message from child window


bindEvent(window, "message", function (e) {
  if (!e || !e.data) {
    return;
  }

  var message = JSON.parse(e.data);

  switch (message.action) {
    case "CLOSE":
      $("#engage-button").click();
      break;

    case "READY":
      $("#engage-button").css("display", "flex");
      break;
  }
}); // "site" class on iframe can be used by enui css to identify where it is loaded
// "landing" or "site"

$(document).ready(function () {
  $("body").append($('<div class="engage-container"> \
			<div class="engage-message-frame engage-hidden" id="engage-message-frame">\
				<iframe id="engage-iframe" class="site" allow="autoplay" frameBorder="0" src=' + iframeTarget + '></iframe>\
			</div>\
			<div class="engage-button" id="engage-button">\
				<img class="engage-chat-icon" src="https://cdn.engage.ninja/common/chat-bubble.png" style="margin-left:1px;" />\
				<img class="engage-close-icon" src="https://cdn.engage.ninja/common/x-close-icon-white.png" style="margin-left:1px;" />\
			</div>\
		</div>')); // TODO: Waits are ugly :(

  setTimeout(function () {
    $("#engage-iframe").contents().find("#root").addClass("site");
  }, 200);
  $("#engage-button").click(function () {
    var el = $("#engage-message-frame");

    if (el.hasClass("engage-hidden") || el.is(":hidden")) {
      el.fadeIn({
        queue: false,
        duration: "medium"
      });
      el.animate({
        bottom: "100px"
      }, "fast");
      var message = JSON.stringify({
        action: "OPEN",
        user_graph_id: window.user_graph_id
      });
      document.getElementById("engage-iframe").contentWindow.postMessage(message, "*");
      el.removeClass("engage-hidden");
      $(".engage-chat-icon").fadeOut();
      $(".engage-close-icon").fadeIn({
        queue: true
      });
    } else {
      el.fadeOut({
        queue: false,
        duration: "fast"
      });
      el.animate({
        bottom: "80px"
      }, "fast");
      $(".engage-close-icon").fadeOut({
        queue: true
      });
      $(".engage-chat-icon").fadeIn({
        queue: true
      });
    }
  });
  setTimeout(function () {
    if (process.env.REACT_APP_AUTO_OPEN == "true") {
      $("#engage-button").click();
      var aud = new Audio("https://cdn.engage.ninja/common/chime.wav");
      aud.play();
    }
  }, process.env.REACT_APP_OPEN_DELAY ? process.env.REACT_APP_OPEN_DELAY : 3500);
});